<template>
  <div class="modal">
    <div class="modal__container">
      <!-- MODAL -->
      <div class="gsp__modal club-registration-modal">
        <button
          class="gsp__modal-close club-registration-modal__close"
          @click="close"
        ></button>
        <div v-if="isFound" class="gsp__modal-found">
          <h3 class="gsp__modal-title">{{ $t("enterIIN") }}</h3>
          <form class="gsp__modal-form" @submit.prevent="seach">
            <input
              v-model="IIN"
              class="gsp__modal-input"
              type="number"
              placeholder="ИИН"
            />
            <div v-if="info" class="gsp__modal-info">
              <div v-if="info.iin" class="gsp__modal-item">
                <p class="gsp__modal-left">{{ $t("IIN") }}</p>
                <p class="gsp__modal-right">{{ info.iin }}</p>
              </div>
              <div v-if="info.name_for_print" class="gsp__modal-item">
                <p class="gsp__modal-left">{{ $t("branch") }}</p>
                <p class="gsp__modal-right">{{ info.name_for_print }}</p>
              </div>
              <div v-if="info.address" class="gsp__modal-item">
                <p class="gsp__modal-left">{{ $t("address") }}</p>
                <p class="gsp__modal-right">{{ info.address }}</p>
              </div>
              <div v-if="info.slot_time" class="gsp__modal-item">
                <p class="gsp__modal-left">{{ $t("issueStatus") }}</p>
                <p class="gsp__modal-right">{{ info.slot_time }}</p>
              </div>
              <div v-if="info.phone" class="gsp__modal-item">
                <p class="gsp__modal-left">{{ $t("phoneNumber") }}:</p>
                <p class="gsp__modal-right">{{ info.phone }}</p>
              </div>
              <div v-if="info.month_name" class="gsp__modal-item">
                <p class="gsp__modal-left">{{ $t("month") }}</p>
                <p class="gsp__modal-right">{{ info.month_name }}</p>
              </div>
            </div>
            <button class="gsp__modal-btn" type="submit">
              {{ $t(btnLabel) }}
            </button>
          </form>
        </div>

        <div v-else class="gsp__modal-not">
          <h3 class="gsp__modal-title">
            {{ $t("IINisNot") }}
          </h3>
          <img
            class="gsp__modal-lemon"
            src="@/assets/img/gsp/lemon.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIIN } from "@/api";
export default {
  name: "AppModalGsp",
  data() {
    return {
      isFound: true,
      IIN: "",
      info: null,
      btnLabel: "toFind",
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.isFound = false;
    },
    seach() {
      if (this.info === null) {
        getIIN(this.IIN).then((IIN) => {
          this.info = IIN;
          this.btnLabel = "close";
          if (this.info.iin === null) {
            this.isFound = false;
          } else {
            this.isFound = true;
          }
        });
      } else {
        this.info = null;
        this.IIN = "";
        this.btnLabel = "toFind";
      }
    },
  },
};
</script>
